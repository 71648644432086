import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const PageTemplate = ({ data }) => {
  return (
    <Layout>
      <Seo title={data.contentfulPage.title} />
      <div
        className="text-center h-[235px] pt-[120px] mb-10 border-b shadow"
        style={{
          backgroundImage:
            'url(//images.ctfassets.net/tyvopq0wgzhv/5cqfdAJZKc5jw4GalSwvWB/9e2080a0a23de4e399fc0042106fa8f5/containers.jpg)',
        }}
      >
        <h1 className="text-4xl font-bold text-[#07285a]">
          {data.contentfulPage.title}
        </h1>
      </div>
      <div
        className="prose px-4 lg:px-0 mx-auto max-w-screen-xl"
        dangerouslySetInnerHTML={{
          __html: data.contentfulPage.content?.childMarkdownRemark?.html,
        }}
      />
    </Layout>
  );
};

export default PageTemplate;

export const contentfulPageFragment = graphql`
  fragment ContentfulPageFragment on ContentfulPage {
    content {
      childMarkdownRemark {
        html
      }
    }
    slug
    title
  }
`;

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...ContentfulPageFragment
    }
  }
`;
